import React from 'react';
import { graphql } from "gatsby"
import PartnersIcons from "../../components/partners-icons";
import { Helmet } from "react-helmet";
import HackerText from "../../components/hackerText";


const Partners = ({ data }) => {
  return (
    <div className="mb-5 pb-5 hero-padding bg-gradient ">
      <Helmet>
        <title>Hackathon Partners | TRONDAO</title>
        <meta name="description" content="Prestigious institutions in the crypto arena judge the blockchain projects in the Grand Hackathon expanding TRON DAO’s ecosystem. See who participates." />
        <meta property="og:description" content="Prestigious institutions in the crypto arena judge the blockchain projects in the Grand Hackathon expanding TRON DAO’s ecosystem. See who participates." />
        <meta property="og:title" content="Hackathon Partners | TRONDAO" />
      </Helmet>
      <div className="w-100 pb-4 mx-auto" style={{ maxWidth: '1920px' }}>
        <h2 id="partners" class="w-100 text-center section-header text-white">Partners</h2>
        <p className="section-description mx-auto" style={{ maxWidth: '982px' }}>Crypto’s most prestigious institutions come together to offer their expertise as they judge
          all around blockchain inventions and accelerate the growth of the TRON DAO ecosystem.</p>
      </div>
      <PartnersIcons data={data.allWpPartner} />
    </div>
  )
}

export default Partners

export const pageQuery = graphql`
query {
  allWpPartner(sort: {order: ASC, fields: menuOrder}) {
    edges {
      node {
        id
        uri
        title
        hackathonSeasonsAndTracks {
          hackathonSeason
          hackathonTrack
        }
        partnerInfo {
          partnerUrl
          partnerBio
          partnerLogo {
            sourceUrl
          }
        }
      }
    }
  }
}
`